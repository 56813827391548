#login-container {
  position: absolute;
  background: rgba(127, 71, 221, 1);
  background: -moz-linear-gradient(top, rgba(127, 71, 221, 1) 0%, rgba(153, 51, 153, 1) 100%);
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(127, 71, 221, 1)),
    color-stop(100%, rgba(153, 51, 153, 1))
  );
  background: -webkit-linear-gradient(top, rgba(127, 71, 221, 1) 0%, rgba(153, 51, 153, 1) 100%);
  background: -o-linear-gradient(top, rgba(127, 71, 221, 1) 0%, rgba(153, 51, 153, 1) 100%);
  background: -ms-linear-gradient(top, rgba(127, 71, 221, 1) 0%, rgba(153, 51, 153, 1) 100%);
  background: linear-gradient(to bottom, rgba(127, 71, 221, 1) 0%, rgba(153, 51, 153, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7f47dd', endColorstr='#993399', GradientType=0 );
}

#main {
  width: 80px;
  height: auto;
}
