.user-card {
  background: rgba(127, 71, 221, 1);
  border-right: none;
  background: -moz-linear-gradient(top, rgba(127, 71, 221, 1) 0%, rgba(153, 51, 153, 1) 100%);
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(127, 71, 221, 1)),
    color-stop(100%, rgba(153, 51, 153, 1))
  );
  background: -webkit-linear-gradient(top, rgba(127, 71, 221, 1) 0%, rgba(153, 51, 153, 1) 100%);
  background: -o-linear-gradient(top, rgba(127, 71, 221, 1) 0%, rgba(153, 51, 153, 1) 100%);
  background: -ms-linear-gradient(top, rgba(127, 71, 221, 1) 0%, rgba(153, 51, 153, 1) 100%);
  background: linear-gradient(to bottom, rgba(127, 71, 221, 1) 0%, rgba(153, 51, 153, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7f47dd', endColorstr='#993399', GradientType=0 );
  padding: 2em 1em;
}

.user-full-name {
  font-weight: normal;
  text-align: left;
  font-size: medium;
  margin-top: 1em;
}

.logout-link {
  color: white;
  text-align: left;
  font-weight: normal;
  margin-top: -0.5em;
  font-size: small;
}

.avatar-background {
  background-color: black;
}
