html {
  background-color: #eeeeee;
}

body {
  overscroll-behavior-y: contain;
}

#App {
  text-align: center;
}

.MuiDrawer-paper::-webkit-scrollbar {
  display: none;
}

.MuiTypography-root:active {
  color: #993399;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a.MuiTypography-displayBlock {
  text-decoration: none;
}

.MuiList-root::-webkit-scrollbar {
  display: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.d-none {
  display: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
